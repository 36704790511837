import i18n from "../translate";
import { capitalize } from "lodash";

export default {
  parseToErrors(data: any) {
    const errorsArray = this.parseErrorsToArrayOfStrings(data);

    return errorsArray.map((error) => i18n.t(error));
  },

  parseErrorsToArrayOfStrings(data: any): string[] {
    if (typeof data === "string") {
      return [data];
    }

    if (typeof data !== "object") {
      return ["An unexpected error has occurred..."];
    }

    const responseError = data?.response?.data?.error as
      | undefined
      | string
      | {
          name: string;
          message: string;
          details?: {
            errors: { name: string; message: string; path: string[] }[];
          };
        };

    if (typeof responseError === "string") {
      return [responseError];
    }

    if (responseError) {
      return (
        responseError.details?.errors?.map((error) =>
          capitalize(error.message)
        ) ?? [responseError.message || responseError.name]
      );
    }

    if (typeof data.error === "string") {
      return [data.error];
    }

    return [data.message || data.error?.message || ""];
  },
};
