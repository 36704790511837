<template>
  <div id="app">
    <router-view />
    <CookieBanner />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CookieBanner from "/components/CookieBanner.vue";
export default {
  components: {
    CookieBanner,
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
  },

  watch: {
    isLoggedIn(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$router.push("/prihlasit-sa" || "/");
      }
    },
  },
};
</script>
<style lang="scss">
.modal-open {
  overflow: hidden;
}

button {
  border: 0;
  background: none;
}

html {
  scroll-behavior: smooth;
}
</style>
