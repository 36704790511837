import Vue from "vue";
import * as Sentry from "@sentry/vue";
import VueEvents from "vue-events";
import VueTruncate from "vue-truncate-filter";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { version } from "./package.json";
import App from "./App.vue";
import router from "./router";
import { eventLogger } from "./utils/event";
import store from "./utils/store/store";
import i18n from "./utils/translate";
import toast from "./utils/toast";
import Translations from "./utils/translate/translations";
import "./utils/devmini";
import "./utils/event/activityTracker";

// Styles
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./styles/index.scss";
import "/styles/theme.scss";

declare const process: {
  env: Record<string, string>;
};

// Bootstrap
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Vue-events
Vue.use(VueEvents);

// Vue-truncate
Vue.use(VueTruncate);

// Toast
Vue.use(toast, { duration: 3000 });

// Turn off production tip
Vue.config.productionTip = false;

Vue.prototype.profileUrls = {
  student: ["/triedy", "/diplomy", "/profil", "/oblubene-kurzy"],
  teacher: [
    "/news",
    "/pomocnik",
    "/triedy",
    "/diplomy",
    "/profil",
    "/oblubene-kurzy",
  ],
};

// Logger
Vue.prototype.$eventLogger = eventLogger;

// Translate
Translations.setup("sk", {
  //default language
  sk: "Slovak",
  en: "English",
  ua: "Ukrainian",
});

// Sentry
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENV,
  release: version,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets:
        process.env.VUE_APP_SENTRY_TRACE_PROPAGATION_TARGETS?.split(",") ?? [],

      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  sampleRate: Number.parseFloat(process.env.VUE_APP_SENTRY_SAMPLE_RATE),
  tracesSampleRate: Number.parseFloat(
    process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE
  ),
  replaysSessionSampleRate: Number.parseFloat(
    process.env.VUE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
  ),
  replaysOnErrorSampleRate: Number.parseFloat(
    process.env.VUE_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
  ),
});

// Vue
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

Vue.prototype.EventBus = new Vue();
