import loginGuard from "./store/loginGuard";
import { RouteConfig } from "vue-router";
import { Component } from "vue-router/types/router";

export const wRoute = (
  name: string,
  path: string,
  component: Component,
  meta: RouteConfig["meta"],
  beforeEnter?: RouteConfig["beforeEnter"]
): RouteConfig => ({ name, path, component, meta, beforeEnter });

export const wRouteTitle = (
  title: string,
  path: string,
  component: any,
  requiresAuth = false,
  props?: any
): RouteConfig => {
  return {
    path,
    component,
    props,
    meta: { title, requiresAuth },
    beforeEnter: loginGuard,
    name: title,
  };
};
