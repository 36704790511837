import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "./store/store";
import qs from "qs";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params);
};

const injectAuthHeader = (config?: AxiosRequestConfig) => {
  return {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${store.getters["auth/token"]}`,
    },
  };
};

const resolveResponseData = <T>(response: AxiosResponse<T>) => {
  if (typeof response === "object" && "data" in response) {
    return response.data;
  }

  return response;
};

async function catchAuthError<T = any>(callback: Promise<AxiosResponse<T>>) {
  try {
    const response = await callback;
    return resolveResponseData(response);
  } catch (error) {
    if (!axios.isAxiosError(error) || error.response?.status !== 401) {
      throw error;
    }
    store.dispatch("auth/authError");
  }
}

export default {
  async get<T = any>(url: string, config?: AxiosRequestConfig) {
    const response = await axios.get<T>(url, config);
    return resolveResponseData(response);
  },

  async getAuth<T = any>(url: string, config?: AxiosRequestConfig) {
    config = injectAuthHeader(config);
    return await catchAuthError<T>(axios.get<T>(url, config));
  },

  async post<T = any>(
    url: string,
    params: any = null,
    config?: AxiosRequestConfig
  ) {
    const response = await axios.post<T>(url, params, config);
    return resolveResponseData(response);
  },

  async postAuth<T = any>(
    url: string,
    params: any = null,
    config?: AxiosRequestConfig
  ) {
    config = injectAuthHeader(config);
    return await catchAuthError<T>(axios.post<T>(url, params, config));
  },

  async delete<T = any>(url: string, config?: AxiosRequestConfig) {
    const response = await axios.delete<T>(url, config);
    return resolveResponseData(response);
  },

  async deleteAuth<T = any>(url: string, config?: AxiosRequestConfig) {
    config = injectAuthHeader(config);
    return await catchAuthError<T>(axios.delete<T>(url, config));
  },

  async put<T = any>(
    url: string,
    params: any = null,
    config?: AxiosRequestConfig
  ) {
    const response = await axios.put<T>(url, params, config);
    return resolveResponseData(response);
  },

  async putAuth<T = any>(
    url: string,
    params: any = null,
    config?: AxiosRequestConfig
  ) {
    config = injectAuthHeader(config);
    return await catchAuthError<T>(axios.put<T>(url, params, config));
  },
};
