import Vue from "vue";
import VueToast, { ToastOptions, ToastComponent } from "vue-toast-notification";
// import VueCompositionAPI from '@vue/composition-api'
// import { ref } from '@vue/composition-api'
import utils from "./utils";
import "./css/toast.css";

Vue.use(VueToast);
// Vue.use(VueCompositionAPI)

export default {
  _isLoading: false,
  _duration: 0,
  _toasts: [] as { id: string; toast: ToastComponent }[],

  _generateToastId() {
    return `w-toast-${new Date().getTime()}`;
  },

  install(Vue: any, options: ToastOptions) {
    this._duration = options.duration || 1000000;
    Vue.prototype.$toast = this;
  },

  isLoading() {
    return this._isLoading;
  },

  stopLoading() {
    this._isLoading = false;
  },

  error(error = "Error", id = "") {
    this._isLoading = false;
    const ID = id || this._generateToastId();

    this._toasts.push({
      id: ID,
      toast: Vue.$toast.open({
        message: utils.parseToErrors(error).join("<br>"),
        type: "error",
        position: "top",
        duration: this._duration,
        onDismiss: this.clear(ID),
      }),
    });
  },

  success(message = "Success", id = "") {
    this._isLoading = false;
    const ID = id || this._generateToastId();

    this._toasts.push({
      id: ID,
      toast: Vue.$toast.open({
        message: message,
        type: "success",
        position: "top",
        duration: this._duration,
        onDismiss: this.clear(ID),
      }),
    });
  },

  clear_loading() {
    this._isLoading = true;
    this.clear();
  },

  clear(id?: string) {
    if (id) {
      const toastIndex = this._toasts.findIndex((t) => t.id === id);

      if (toastIndex != -1) {
        this._toasts[toastIndex].toast.dismiss();
        this._toasts.splice(toastIndex, 1);
      }
    } else {
      this._toasts = [];
      Vue.$toast.clear();
    }

    return () => undefined;
  },
};
