import axios from "../axios";
import store from "../store/store";
import { EventLogInput, EventLogEventType } from "../types";

const api = {
  log: (payload: EventLogInput) => axios.postAuth("event-logs", payload),
};

const formatLogInput = (
  eventType: EventLogEventType,
  payload: any = {}
): EventLogInput => {
  const userId = store.getters["auth/user"]?.id;

  return {
    data: {
      eventType,
      payload,
      user: userId
        ? {
            connect: [
              {
                id: userId,
              },
            ],
          }
        : null,
    },
  };
};

export const eventLogger = {
  log: (eventType: EventLogEventType, payload: any = {}) => {
    api.log(formatLogInput(eventType, payload));
  },
  userLog: (eventType: EventLogEventType, payload: any = {}) => {
    if (!store.getters["auth/isLoggedIn"]) {
      return;
    }

    api.log(formatLogInput(eventType, payload));
  },
};
