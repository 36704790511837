import { Module } from "vuex";
import axios from "../axios";
import {
  AuthState,
  LoginResponseData,
  LoginRequestData,
  ForgotPasswordRequestData,
  ResetPasswordRequestData,
  UserInputData,
  SetInput,
  CollectionTypeResponse,
  User,
  AuthUser,
} from "../types";
import { compact, isEmpty } from "lodash";
import * as Sentry from "@sentry/vue";

const AUTH_TOKEN_LOCAL_STORAGE_KEY = "auth-token";
const AUTH_USER_LOCAL_STORAGE_KEY = "auth-user";

type UserInput = Omit<
  UserInputData,
  "schoolCreate" | "schoolConnect" | "school" | "subjects" | "heardFrom"
> & {
  school?: SetInput;
  subjects?: SetInput;
  heardFrom?: SetInput;
};

async function formatUserInput(
  data: UserInputData,
  currentState?: AuthUser | null
): Promise<UserInput> {
  const {
    schoolConnect: connectSchool,
    schoolCreate: createSchool,
    school: _school,
    subjects,
    heardFrom,
    ...userData
  } = data;

  let fetchedSubjects: CollectionTypeResponse<any> | undefined;
  let fetchedHeardFromOptions: CollectionTypeResponse<any> | undefined;

  const hasUpdatedSubjects =
    subjects &&
    (!currentState?.subjects ||
      currentState.subjects.length !== subjects.length ||
      currentState.subjects.some((s) => !subjects.includes(s)));

  const hasUpdatedHeardFrom =
    heardFrom &&
    (!currentState?.heardFrom ||
      currentState.heardFrom.length !== heardFrom.length ||
      currentState.heardFrom.some((h) => !heardFrom.includes(h)));

  if (hasUpdatedSubjects) {
    fetchedSubjects = await axios.get("subjects", {
      params: {
        fields: ["id"],
        filters: {
          info: {
            slug: {
              $in: !isEmpty(subjects) ? subjects : [""],
            },
          },
        },
      },
    });
  }

  if (hasUpdatedHeardFrom) {
    fetchedHeardFromOptions = await axios.get("heard-from-options", {
      params: {
        fields: ["id"],
        filters: {
          info: {
            slug: {
              $in: !isEmpty(heardFrom) ? heardFrom : [""],
            },
          },
        },
      },
    });
  }

  const input: UserInput = {
    ...userData,
    username: userData.username || userData.email,
    subjects: fetchedSubjects
      ? {
          set: fetchedSubjects.data.map((subject: any) => ({ id: subject.id })),
        }
      : undefined,
    heardFrom: fetchedHeardFromOptions
      ? {
          set: fetchedHeardFromOptions.data.map((option: any) => ({
            id: option.id,
          })),
        }
      : undefined,
  };

  if (createSchool?.name) {
    const { cityCreate, ...schoolCreate } = data.schoolCreate ?? {};

    let cityId: number | undefined;

    if (cityCreate) {
      const city = await axios.postAuth("cities", {
        data: {
          type: "custom",
          ...cityCreate,
        },
      });

      cityId = city.data.id;
    }

    const school = await axios.postAuth("schools", {
      data: {
        type: "custom",
        ...schoolCreate,
        ...(cityId
          ? {
              city: {
                set: [cityId],
              },
            }
          : {}),
      },
    });

    const schoolId = school.data.id;

    input.school = {
      set: [{ id: schoolId }],
    };
  } else if (connectSchool?.id) {
    const schoolId = connectSchool.id;
    const cityId = connectSchool.cityConnect?.id;

    if (cityId) {
      await axios.put(`schools/${schoolId}`, {
        data: {
          city: {
            connect: [{ id: cityId }],
          },
        },
      });
    }

    input.school = {
      set: [{ id: schoolId }],
    };
  }

  return input;
}

function formatAuthUser(user: User): AuthUser {
  return {
    ...user,
    completedCourses: user.completedCourses ?? [],
    completedPaths: user.completedPaths ?? [],
    favoriteCourses: user.favoriteCourses ?? [],
    favoritePaths: user.favoritePaths ?? [],
    subjects: compact(
      user?.subjects?.map((subject) => subject?.info?.slug) ?? []
    ),
    heardFrom: compact(
      user?.heardFrom?.map((option) => option?.info?.slug) ?? []
    ),
  };
}

const authStore: Module<AuthState, {}> = {
  namespaced: true,
  state: {
    status: "default",
    _token: localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY) || "",
    user: JSON.parse(localStorage.getItem(AUTH_USER_LOCAL_STORAGE_KEY) || "{}"),
  },
  mutations: {
    "auth-request": (state) => {
      state.status = "loading";
    },
    "auth-success": (state, data: LoginResponseData) => {
      state.status = "success";

      if (data.token) {
        state._token = data.token;
        localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, data.token);
      }

      if (data.user) {
        const authUser = formatAuthUser(data.user);
        state.user = authUser;

        localStorage.setItem(
          AUTH_USER_LOCAL_STORAGE_KEY,
          JSON.stringify(authUser)
        );
        Sentry.setUser({
          id: data.user.id,
          username: data.user.username,
          email: data.user.email,
          segment: data.user.type,
        });
      }
    },
    "auth-error": (state) => {
      state.status = "error";
      state._token = "";

      localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
      localStorage.removeItem(AUTH_USER_LOCAL_STORAGE_KEY);

      Sentry.setUser(null);
    },
    logout(state: AuthState) {
      state.status = "default";
      state._token = "";

      state.user = {} as any;

      localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
      localStorage.removeItem(AUTH_USER_LOCAL_STORAGE_KEY);

      Sentry.setUser(null);
    },
  },
  actions: {
    async login({ commit }, credentials: LoginRequestData) {
      commit("auth-request");

      try {
        const response = await axios.post("auth/local", credentials);

        const token = response.jwt;
        const user = response.user;

        commit("auth-success", {
          token,
          user,
        });

        return response;
      } catch (error) {
        commit("auth-error");
        throw error;
      }
    },
    async signup({ commit, state }, data: UserInputData) {
      commit("auth-request");

      try {
        const input = await formatUserInput(data, state.user);
        const response = await axios.post("auth/local/register", input);

        const token = response.jwt;
        const user = response.user;

        commit("auth-success", {
          token,
          user,
        });

        return response;
      } catch (error) {
        commit("auth-error");
        throw error;
      }
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        resolve(null);
      });
    },
    async authError({ commit }) {
      return new Promise((resolve) => {
        commit("auth-error");
        resolve(null);
      });
    },
    async forgotPassword(_, credentials: ForgotPasswordRequestData) {
      return axios.postAuth("auth/forgot-password", credentials);
    },
    async resetPassword(_, credentials: ResetPasswordRequestData) {
      return axios.postAuth("auth/reset-password", credentials);
    },
    async user({ commit, state }) {
      if (!state._token) return state.user;
      const user = await axios.getAuth<User>("users/me");

      commit("auth-success", { user });
      return state.user;
    },
    async updateUser({ commit, state }, data: UserInputData) {
      const input = await formatUserInput(data, state.user);

      if (!state.user) {
        commit("auth-error");
        throw Error("Missing logged in user!");
      }

      await axios.putAuth(`user/me`, input);
      const user = await axios.getAuth<User>("users/me");

      commit("auth-success", { user });
      return state.user;
    },
  },
  getters: {
    isLoggedIn: (state) => {
      return !!state._token;
    },
    authStatus: (state) => {
      return state.status;
    },
    user: (state) => {
      return state.user;
    },
    token: (state) => {
      return state._token;
    },
  },
};

export default authStore;
