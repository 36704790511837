import Vue from "vue";
import VueRouter from "vue-router";

import VueGtag from "vue-gtag";
import VueGtm from "@gtm-support/vue2-gtm";

import VueFacebookPixel from "vue-analytics-facebook-pixel";

// Get cookies info
import cookies from "./getCookie";

export default {
  addGoogleAnalytics(router: VueRouter, config = []) {
    if (
      process.env.VUE_APP_GOOGLE_ANALYTICS_ID &&
      cookies.getCookie()[Object.keys(cookies.getCookie() ?? [])?.[1]]
    ) {
      Vue.use(VueGtag, {
        config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
      });
    }
  },

  addGoogleTagManager(router: VueRouter) {
    if (
      process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID &&
      cookies.getCookie()[Object.keys(cookies.getCookie())[1]]
    ) {
      Vue.use(VueGtm, {
        id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID,
        debug: false,
        vueRouter: router,
        ignoredViews: [],
      });
    }
  },

  addFacebookPixel(router: VueRouter) {
    if (
      process.env.VUE_APP_FACEBOOK_PIXEL_ID &&
      cookies.getCookie()[Object.keys(cookies.getCookie())[3]]
    ) {
      Vue.use(VueFacebookPixel, {
        id: process.env.VUE_APP_FACEBOOK_PIXEL_ID,
        router,
      });
    }
  },
};
