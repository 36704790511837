import { Plugins } from "@capacitor/core";
import i18n from "./index";
import moment from "moment";
import { LocaleMessages, PostTranslationHandler } from "vue-i18n";

const { Storage } = Plugins; // Device,

class Translations {
  n = "🔴";
  y = "🟢";
  locale = "";
  defaultLocale = "";
  settings = {};
  constructor() {
    this._loadOrGetLocale();
    moment.locale("sk");
  }
  setup = (defaultLocale: string, settings: Record<string, string>) => {
    this.defaultLocale = defaultLocale;
    this.settings = settings;
  };
  setLocale = (locale: string) => {
    this.locale = locale;
    this._switchTranslationSign();
    i18n.locale = locale;
    Storage.set({ key: "locale", value: locale });
    // Update the document description for SEO
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        `${i18n.t("platform-full-of-courses").toString()} ${i18n
          .t("for-schools-teachers-students")
          .toString()}.`
      );
  };
  getLocale = () => {
    return this.locale;
  };
  getLocales = () => {
    return this.settings;
  };
  getDefaultLocale = () => {
    return this.defaultLocale;
  };
  missingTranslation = (_locale: string, key: string) => {
    // if (_env('TRANSLATION_DEBUG')) {
    //   return key + this.n
    // } else
    return key;
  };
  postTranslation: PostTranslationHandler = (string, key) => {
    // if (_env('TRANSLATION_DEBUG')) {
    //   if (string === '') return key + this.n
    //   return string.includes(this.n) ? string : string + this.y
    // } else
    return (string === "" ? key : string) ?? "";
  };
  loadLocaleMessages(): LocaleMessages {
    const locales = require.context("/lang", true, /[A-Za-z0-9-_,\s]+\.json$/i);

    const messages = {} as LocaleMessages;

    locales.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });

    return messages;
  }
  _switchTranslationSign() {
    this.n = this.locale === this.defaultLocale ? "🟢" : "🔴";
  }
  async _loadOrGetLocale() {
    const locale = await Storage.get({ key: "locale" });
    // const storageLocale = await Storage.get({ key: 'locale' })
    // if (!storageLocale.value) {
    // 	const deviceLangCode = await Device.getLanguageCode()
    // 	locale = deviceLangCode.value.split('-')[0]
    // } else locale = storageLocale.value
    if (locale?.value) this.locale = locale.value;
    else this.locale = this.defaultLocale;
    this.setLocale(this.locale);
  }
}
export default new Translations();
