import { wRouteTitle } from "./utils/route";
import Vue from "vue";
import VueRouter from "vue-router";
import { createRouterLayout } from "vue-router-layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: createRouterLayout(
      (layout) => import(`/components/layouts/${layout}/${layout}.vue`)
    ),
    children: [
      // home
      wRouteTitle("Domov", "/", () => import("/pages/index.vue")),
      wRouteTitle(
        "Domov učitelia",
        "/pre-ucitelov",
        () => import("/pages/teachers.vue")
      ),

      // static
      wRouteTitle(
        "Podporte nás",
        "/podporte-nas",
        () => import("/pages/support-us.vue")
      ),
      wRouteTitle("O nás", "/o-nas", () => import("/pages/about.vue")),
      wRouteTitle(
        "2% z daní",
        "/daruj-2-percenta",
        () => import("/pages/give-2-percents.vue")
      ),
      wRouteTitle("Partneri", "/partneri", () => import("/pages/partners.vue")),

      // Zmudri do skol
      wRouteTitle(
        "Zmudri do škôl",
        "/zmudri-do-skol",
        () => import("/pages/do-skol.vue")
      ),

      // account
      wRouteTitle(
        "Prihlásiť sa",
        "/prihlasit-sa",
        () => import("/pages/login/index.vue")
      ),
      wRouteTitle(
        "Registrácia",
        "/registracia",
        () => import("/pages/register.vue")
      ),
      wRouteTitle(
        "Zabudnuté heslo",
        "/prihlasit-sa/zabudnute-heslo",
        () => import("/pages/login/forgotPassword.vue")
      ),
      wRouteTitle(
        "Podmienky",
        "/prihlasit-sa/podmienky",
        () => import("/pages/login/terms.vue"),
        false,
        { registrationLink: "/registracia" }
      ),
      wRouteTitle(
        "Resetovať heslo",
        "/prihlasit-sa/resetovat-heslo",
        () => import("/pages/login/resetPassword.vue")
      ),

      // profile features
      wRouteTitle(
        "Profil",
        "/profil",
        () => import("/pages/profile.vue"),
        true
      ),
      wRouteTitle(
        "Moje kurzy",
        "/oblubene-kurzy",
        () => import("/pages/favourite-courses.vue"),
        true
      ),
      wRouteTitle(
        "Diplomy",
        "/diplomy",
        () => import("/pages/certificates.vue"),
        true
      ),
      wRouteTitle(
        "Pomocník",
        "/pomocnik",
        () => import("/pages/user-manual.vue"),
        true
      ),
      wRouteTitle("Novinky", "/news", () => import("/pages/news.vue"), true),
      wRouteTitle(
        "Triedy",
        "/triedy",
        () => import("/pages/groups/index.vue"),
        true
      ),
      wRouteTitle(
        "Trieda",
        "/triedy/:id",
        () => import("/pages/groups/[id]/index.vue"),
        true
      ),
      wRouteTitle(
        "Uloha",
        "/triedy/:id/uloha/:taskId",
        () => import("/pages/groups/[id]/tasks/[taskId].vue"),
        true
      ),
      wRouteTitle(
        "Študent",
        "/triedy/:id/:studentId",
        () => import("/pages/groups/[id]/[studentId].vue"),
        true
      ),

      // kurzy, seria, lekcia
      wRouteTitle("Kurzy", "/kurzy", () => import("/pages/courses.vue")),
      wRouteTitle("Séria", "/seria/:pathSlug", () => import("/pages/path.vue")),
      wRouteTitle(
        "Lekcia",
        "/kurz/:courseSlug",
        () => import("/pages/course/[courseSlug].vue")
      ),

      // error not found
      wRouteTitle("404", "/404", () => import("/pages/404.vue")),
      wRouteTitle("404 *", "*", () => import("/pages/404.vue")),
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const routeTitle = to.meta?.title;
  document.title = `${routeTitle ? `${routeTitle} | ` : ""}Zmudri`;

  if (to !== from) {
    Vue.$toast.clear();
  }

  // remember last route to return to it after registration / login.
  switch (to.fullPath) {
    case "/prihlasit-sa":
    case "/registracia":
      break;
    default:
      localStorage.setItem("LAST_FROM_ROUTE", to.fullPath);
  }

  next();
});

export default router;
