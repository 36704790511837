import { eventLogger } from ".";

let lastUserActivity: Date, userHasFocus: boolean;

const afkThreshold = 60; //seconds
const logTimeInterval = 60; //seconds

window.addEventListener("mousemove", () => (lastUserActivity = new Date()));
window.addEventListener("keypress", () => (lastUserActivity = new Date()));

window.onblur = () => {
  userHasFocus = false;
};
window.onfocus = () => {
  userHasFocus = true;
};

export const monitoringInterval = setInterval(() => {
  const now = new Date();

  if (!lastUserActivity) {
    lastUserActivity = new Date();
  }

  const diff = (now.getTime() - lastUserActivity.getTime()) / 1000;

  if (diff < afkThreshold || userHasFocus) {
    eventLogger.userLog("user.activityCheck", { timestamp: new Date() });
  }
}, logTimeInterval * 1000);
