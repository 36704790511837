import Vue from "vue";
import VueI18n from "vue-i18n";
import Translations from "./translations";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: Translations.getLocale(),
  fallbackLocale: Translations.getDefaultLocale(),
  messages: Translations.loadLocaleMessages(),
  missing: Translations.missingTranslation,
  postTranslation: Translations.postTranslation,
  silentTranslationWarn: true,
});

export default i18n;
