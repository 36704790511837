import store from "./store";
import { NavigationGuard } from "vue-router";

const loginGuard: NavigationGuard = (to, _from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["auth/isLoggedIn"]) {
      next("/prihlasit-sa");
      return;
    }
  }

  return next();
};

export default loginGuard;
